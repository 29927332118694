@use 'styles/variables' as *;

.footer {
  text-align: center;
  padding-bottom: 110px;
  position: relative;
  & p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }
}