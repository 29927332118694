@use 'styles/variables' as *;
@use 'styles/breakpoints' as *;

.topMoversWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 24px;
  padding: 20px 20px 4px;
  background-color: rgba(43, 27, 78, 0.4);
  @include media('screen', '<phone') {
    align-items: center;
  }
}

.topMoversContent {
  width: 100%;
  margin-top: 12px;
  padding-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // gap: 10px;
    width: 100%;
  }
}

.topMoverItem {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid rgb(56, 50, 65);
  border-radius: 24px;
  padding: 16px;
  min-width: 190px;
  &:last-child {
    margin-right: 0;
  }
  @include media('screen', '<phone') {
    width: 180px;
    justify-content: flex-start;
  }
}

.topMoverText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  margin-left: 8px;
  & svg {
    margin: 0 -2px 0 -6px;
  }
}
