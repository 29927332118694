@use 'styles/variables' as *;

.warningBanner {
  width: 100%;
  background: $yellow1;
  color: $bgColor;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 32px 8px 16px;
  & span {
    margin-left: 8px;
    width: calc(100% - 32px);
    text-align: left;
  }
}

.closeBanner {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}