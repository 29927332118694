@use 'styles/variables' as *;
@use 'styles/breakpoints' as *;

.stakeButton {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  border: 1px solid $primary;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;
  & span {
    font-size: 16px;
    font-weight: 600;
  }
  &.Mui-disabled {
    border: none;
    background-image: none;
    background-color: $secondary1;
  }
}