@use 'styles/variables' as *;

.minimalCardWrapper {
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  & p {
    font-size: 16px;
    line-height: 24px;
  }
}

.minimalCardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}