@use 'styles/variables' as *;
@use 'styles/breakpoints' as *;

.tableContainer {
  & .MuiTableContainer-root {
    overflow: unset;
  }
  & thead {
    height: 53px
  }
  & tbody tr {
    height: 57px
  }
  & thead tr th, & tbody tr td {
    font-size: 14px;
    border-bottom: 1px solid $divider;
    &.buttonCell {
      width: 110px;
      padding: 6px 10px 6px 0;
      & button {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 40;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  & thead tr th {
    padding: 7px 6px;
    cursor: pointer;
    &:first-child {
      padding: 7px 0px 7px 23px;
      @include media("screen", "<tablet") {
        padding: 7px 6px 7px 13px;
      }
    }
    &.buttonCell {
      & > div > div {
        width: 100%;
      }
      & button {
        color: $textSecondary;
      }
    }
  }
  & tbody tr td {
    padding: 6px;
    border-bottom: none;
    &:first-child {
      padding: 6px 0px 6px 15px;
      @include media("screen", "<tablet") {
        padding: 6px;
      }
    }
    &.buttonCell.MuiButton-textPrimary {
      & button {
        color: white;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}