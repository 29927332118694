$primary:#448aff;
$primaryDark:#1C2938;
$secondary:#344252;
$secondary1:#252833;
$secondary2:#232734;
$secondary3:#2c3242;
$textPrimary:#c7cad9;
$textSecondary:#696c80;
$textDisabled:#626680;
$textHint:#636780;
$bgColor:#12131a;
$bgPalette:#1b1e29;
$grey1:rgba(99, 103, 128, 0.1);
$grey2:#3e4252;
$grey3:#1b1d26;
$grey4:rgba(68, 138, 255, 0.24);
$grey5:rgba(225, 190, 231, 0.1);
$grey6: #c5cbe0;
$grey7: rgba(255, 255, 255, 0.87);
$grey8: rgb(27, 32, 43, 0.9);
$grey9: rgb(43, 45, 59, 0.2);
$grey10: #1d212c;
$grey11: rgba(40, 145, 249, 0.1);
$grey12: #404557;
$grey13: rgba(0, 0, 0, 0.075);
$grey14: rgba(105, 108, 128, 0.12);
$grey15: rgba(27, 30, 41, 0);
$grey16: rgba(0, 0, 0, 0.01);
$grey17: rgba(0, 0, 0, 0.04);
$grey18: #999;
$grey19: #b6b9cc;
$grey20: #B4C2D5;
$grey21: rgba(0, 0, 0, 0.15);
$grey22: rgba(255, 255, 255, 0.47);
$grey23: rgba(212, 229, 255, 0.8);
$grey24: rgba(15, 198, 121, 0.2);
$grey25: rgb(199, 202, 217);
$grey26: #444444;
$grey27: #2c3142;
$grey28: #2d3447;
$successMain:#0fc679;
$successDark:#1DB2D5;
$successLight:rgba(15, 198, 121, 0.1);
$errorMain:#ff5252;
$errorDark:#f00;
$errorLight:rgba(255, 82, 82, 0.1);
$divider:rgba(130, 177, 255, 0.08);
$blue: #3d71ff;
$blue1: #004ce6;
$blue2: #0098ff;
$blue3: #00cff3;
$blue4: #5294FF;
$blue5: #4389fd;
$blue6: rgba(68, 138, 255, 0.3);
$blue7: rgba(68, 138, 255, 0.5);
$green1: #64fbd3;
$green2: rgba(15, 198, 121, 0.12);
$yellow1: #e59840;
$yellow2: rgba(255, 160, 0, 0.3);
$yellow3: #ffa000;
