@use 'styles/variables' as *;

.barChartItem {
  background:
    linear-gradient(to bottom, $green1, $blue3 34%, $blue2 63%, $blue1);
  opacity: 0.1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
}

.categoryValues {
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
  & p {
    font-size: 14px;
    color: $textDisabled;
  }
}