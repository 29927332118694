@use 'styles/variables' as *;

.currencyLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    &.ethereumLogo {
      box-shadow: 0px 6px 10px $grey13;
    }  
  }
}