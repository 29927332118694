@use 'styles/variables' as *;
@use 'styles/breakpoints' as *;

.rewardsSlider {
  width: 1072px;
  display: flex;
  justify-content: space-between;
  margin: 64px auto 56px;
  & .slick-list {
    width: 100%
  }
  & .slick-slide {
    padding: 0 20px;
    @include media("screen", "<phone") {
      padding: 0 6px;
    }
  }
  & .slick-arrow {
    color: $successLight;
    width: 32px;
    height: 32px;
  }
  @include media("screen", "<desktop") {
    width: 776px;
  }
  @include media("screen", "<tablet") {
    width: 360px
  }
  @include media("screen", "<phone") {
    width: 320px
  }
}

.rewardsSliderItem {
  border-radius: 32px;
  padding: 32px 22px;
  position: relative;
  & .rewardIcon {
    position: absolute;
    display: flex;
    top: 32px;
    left: 22px;
  }
  & h5 {
    margin-left: 70px;
    text-align: left;
  }
  & .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    & p {
      display: flex;
      align-items: center;
      & svg {
        margin-left: 4px
      }
    }
  }
  & .rewardApyWrapper {
    background: $green2;
    padding: 0 4px;
    border-radius: 5px;
  }
  & button {
    height: 40px;
    font-size: 16px;
    background: $primary;
    border-radius: 20px;
  }
}