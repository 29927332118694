@use 'styles/variables' as *;

.addressInput {
  border-radius: 20px;
  padding: 12px 24px;
  text-align: left;
  & input {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    margin-top: 16px;
  };
  & a {
    color: $textPrimary;
    text-decoration: none;
  }
}